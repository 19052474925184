@keyframes show-overlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

ptz-modal {
  .ptz-modal,
  .ptz-modal-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    position: fixed;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ptz-modal {
    &-overlay {
      pointer-events: auto;
      background-color: rgba(0, 0, 0, 0.64);
      transition: visibility 0s linear 100ms, opacity 600ms;
      animation: show-overlay 0.6s ease-in-out;
    }

    &-container {
      z-index: 10;
      opacity: 1;
      max-width: 90%;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      padding-top: $petz-spacing-md;
      border: $petz-border-none;
      border-radius: $petz-rounded-md;
      background-color: $petz-color-neutral-white;

      &-buttons-skeleton {
        margin-right: $petz-spacing-sm;
      }
    }

    &-max-height {
      height: auto !important;
    }

    &-open {
      visibility: visible;

      .ptz-drawer-overlay,
      .ptz-btn {
        visibility: visible;
      }
    }

    &-close {
      visibility: hidden;

      .ptz-modal-overlay,
      .ptz-btn {
        opacity: 0;
        visibility: hidden;
      }
    }

    &-sm,
    &-md,
    &-lg {
      .ptz-btn {
        min-width: 7.5rem;
      }
    }

    &-sm {
      width: 20.438rem;
      height: 20.063rem;

      .ptz-modal-footer-buttons {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .ptz-btn {
          margin-left: 0;
        }
      }
    }

    &-md {
      width: 30rem;
      height: 24.063rem;
    }

    &-lg {
      width: 45rem;
      height: 31.313rem;
    }

    &-title {
      display: inline-flex;
      padding-bottom: $petz-spacing-sm;
      border-bottom: $petz-border-sm solid $petz-color-neutral-light;
      margin: 0 $petz-spacing-md;

      &-text {
        flex: 1;
        margin: 0;
        text-align: left;
        font-weight: $petz-font-weight-bold;
        font-size: $petz-font-size-sm;
        line-height: $petz-font-lineheight-lg;
        color: $petz-color-neutral-darker-accent;
      }

      .ptz-icon {
        cursor: pointer;
        margin-left: $petz-spacing-sm;
        color: $petz-color-neutral-darker-accent;
      }
    }

    &-content {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: $petz-font-size-2xs;
      font-weight: $petz-font-weight-regular;
      color: $petz-color-neutral-dark;
      line-height: $petz-font-lineheight-lg;
      margin: $petz-spacing-sm $petz-spacing-md;

      &.ptz-modal-scrollable {
        overflow: scroll;

        [slot="modal-content"] {
          height: 100%;
        }
      }
    }

    &-footer {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin: $petz-spacing-sm $petz-spacing-md;

      &-buttons {
        .ptz-btn {
          margin-left: $petz-spacing-sm;
        }
      }

      &-full-width {
        display: block;
        .ptz-btn {
          width: 100%;
        }
      }
    }

    &-divider {
      border-top: 1px solid $petz-color-neutral-light;
    }
  }

  .ptz-modal-content-skeleton,
  .ptz-modal-footer-full-width-skeleton{
    width: 100%;
  }
}
