import { EnumToArray } from '../../../utils/enumToArray';

enum Size {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

export const PTZModalConfigList = {
  Size: EnumToArray([Size]),
};

export const PTZModalConfig = {
  Size,
};
