import { Component, Event, EventEmitter, Host, Prop, h } from '@stencil/core';
import { getSkeletonWidth } from '../../utils/utils';
import { PTZModalConfig } from './types/ptz-modal.enums';
import { PTZModalTypes } from './types/ptz-modal.types';

@Component({
  tag: `ptz-modal`,
  styleUrl: 'ptz-modal.scss',
  shadow: false,
})
export class PTZModal {
  /** Tamanho do modal */
  @Prop() size: PTZModalTypes.Size = PTZModalConfig.Size.Medium;

  /** Título do modal */
  @Prop() titleLabel: string;

  /** Label botão primary */
  @Prop() labelPrimaryButton: string;

  /** Label botão secondary */
  @Prop() labelSecondaryButton: string | null;

  /** Prop para tornar o botão tamanho 100% */
  @Prop() primaryButtonFullWidth: boolean = false;

  /** Prop para abrir o modal */
  @Prop({ mutable: true }) isOpen: boolean = false;

  /** Prop para  desabilitar botoes do modal */
  @Prop() disabledButtons: boolean = false;

  /** Prop para  desabilitar o botão primary */
  @Prop() disablePrimaryButton?: boolean = false;

  /** Prop para  desabilitar o botão secondary */
  @Prop() disableSecondaryButton?: boolean = false;

  /** Estado de renderização */
  @Prop() skeleton: boolean = false;

  /** Prop para controlar o fechamento da modal quando clicar nos botoes do modal */
  @Prop() shouldCloseOnBtnClick: boolean = true;

  /** Prop para controlar quando o divisor é visível*/
  @Prop() dividerIsVisible: boolean = false;

  /** Prop para controlar se a altura é fixa*/
  @Prop() isFixedHeight: boolean = true;

  /** Prop para ativar rolagem de conteúdo */
  @Prop() scrollable: boolean = false;

  /** Prop para controlar o fechamento da modal quando clicar no overlay */
  @Prop() shouldCloseOnOverlayClick: boolean = true;

  /** Prop para controlar se o ícone de fechar deve aparecer ou não */
  @Prop() shouldShowCloseIcon: boolean = true;

  /** Evento de click no botão primary */
  @Event({
    eventName: 'clickPrimaryButton',
    composed: true,
  })
  clickPrimaryButton: EventEmitter<boolean>;

  /** Evento de click no botão secondary */
  @Event({
    eventName: 'clickSecondaryButton',
    composed: true,
  })
  clickSecondaryButton: EventEmitter<boolean>;

  /** Evento para fechar o modal */
  @Event({
    eventName: 'dismissModal',
    composed: true,
  })
  dismissModal: EventEmitter<boolean>;

  /** Evento de click no ícone de fechar */
  @Event({
    eventName: 'clickCloseIcon',
    composed: true,
  })
  clickCloseIcon: EventEmitter<boolean>;

  private handleDismissModal() {
    this.isOpen = !this.isOpen;
    this.dismissModal.emit(this.isOpen);
  }

  private handleOverlayClick() {
    if (this.shouldCloseOnOverlayClick) {
      this.handleDismissModal();
    }
  }

  render() {
    const fullWidthBtn = this.primaryButtonFullWidth && !this.labelSecondaryButton ? 'ptz-modal-footer-full-width' : '';

    const toggleClass = `ptz-modal-${this.isOpen ? 'open' : 'close'}`;

    const scrollableClass = this.scrollable ? 'ptz-modal-scrollable' : '';

    const maxHeight = !this.isFixedHeight ? 'ptz-modal-max-height' : '';

    const showDivider = this.dividerIsVisible;

    const skeletonHeight = !this.isFixedHeight ? 150 : 208;

    const intervals = [
      { max: 44, w: 392 },
      { max: 32, w: 294 },
      { max: 22, w: 196 },
      { max: 17, w: 144 },
    ];

    const handleSecondaryBtnClick = () => {
      this.clickSecondaryButton.emit();
      if (this.shouldCloseOnBtnClick) {
        this.handleDismissModal();
      }
    };

    const handlePrimaryBtnClick = () => {
      this.clickPrimaryButton.emit();
      if (this.shouldCloseOnOverlayClick) {
        this.handleDismissModal();
      }
    };

    const handleCloseIconClick = () => {
      this.clickCloseIcon.emit();
      this.handleDismissModal();
    };

    const renderModalFooter =
      this.labelSecondaryButton && this.labelPrimaryButton ? (
        <div class="ptz-modal-footer ptz-modal-footer-buttons">
          {this.skeleton ? (
            <ptz-skeleton class={'ptz-modal-container-buttons-skeleton'} width={120} height={48}></ptz-skeleton>
          ) : (
            <ptz-button size="md" kind="secondary" appearance="light" label={this.labelSecondaryButton} onClick={handleSecondaryBtnClick} disabled={this.disableSecondaryButton} />
          )}

          {this.skeleton ? (
            <ptz-skeleton width={120} height={48}></ptz-skeleton>
          ) : (
            <ptz-button size="md" label={this.labelPrimaryButton} onClick={handlePrimaryBtnClick} disabled={this.disablePrimaryButton} />
          )}
        </div>
      ) : this.labelSecondaryButton ? (
        <div class="ptz-modal-footer ptz-modal-footer-buttons">
          {this.skeleton ? (
            <ptz-skeleton width={120} height={48}></ptz-skeleton>
          ) : (
            <ptz-button size="md" kind="secondary" appearance="light" label={this.labelSecondaryButton} onClick={handleSecondaryBtnClick} disabled={this.disableSecondaryButton} />
          )}
        </div>
      ) : (
        <div class={`ptz-modal-footer ${fullWidthBtn}`}>
          {this.skeleton ? (
            <ptz-skeleton class={'ptz-modal-footer-full-width-skeleton'} height={48}></ptz-skeleton>
          ) : (
            <ptz-button size="md" label={this.labelPrimaryButton} onClick={handlePrimaryBtnClick} disabled={this.disablePrimaryButton} />
          )}
        </div>
      );

    return (
      <Host>
        <div class={`ptz-modal ${toggleClass}`}>
          <div class="ptz-modal-overlay" onClick={() => this.handleOverlayClick()}></div>
          <div class={`ptz-modal-container ${maxHeight} ptz-modal-${this.size}`}>
            <div class="ptz-modal-title">
              <p class="ptz-modal-title-text">
                {' '}
                {this.skeleton ? <ptz-skeleton width={getSkeletonWidth(this.titleLabel.length, intervals, 132)} height={24}></ptz-skeleton> : this.titleLabel}
              </p>
              {this.skeleton ? (
                <ptz-skeleton width={24} height={24}></ptz-skeleton>
              ) : (
                this.shouldShowCloseIcon && <ptz-icon size="lg" variant="line" name="times-circle" aria-label="Ícone de fechar" onClick={handleCloseIconClick} />
              )}
            </div>
            <div class={`ptz-modal-content ${scrollableClass}`}>
              {this.skeleton ? <ptz-skeleton class={'ptz-modal-content-skeleton'} height={skeletonHeight}></ptz-skeleton> : <slot name="modal-content" />}
            </div>
            {showDivider && (this.skeleton ? <ptz-skeleton width={480} height={2}></ptz-skeleton> : <div class="ptz-modal-divider"></div>)}
            {!this.disabledButtons && renderModalFooter}
          </div>
        </div>
      </Host>
    );
  }
}
